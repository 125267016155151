import { ValueOfMutationKeys, ValueOfQueryKeys } from '@/shared/types';
import * as MUTATION_KEYS from './mutationKeys/mutationKeysWithAutoErrorHandling';
import * as QUERY_KEYS from './queryKeys/queryKeysWithAutoErrorHandling';

export const STATUS_TYPES = {
  SUCCESS: 'Успіх',
  ERROR: 'Помилка',
  WARNING: 'Попередження',
  INFO: 'Інформація',
};

export const NOTIFICATION_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
} as const;

export const SUCCESS_COPIES = {
  CREATE_CATEGORY: { title: STATUS_TYPES.SUCCESS, description: 'Категорія успішно створена' },
  UPDATE_CATEGORY: { title: STATUS_TYPES.SUCCESS, description: 'Категорію успішно оновлено' },
  REMOVE_CATEGORY: { title: STATUS_TYPES.SUCCESS, description: 'Категорію успішно видалено' },
};

export const ERROR_COPIES = {
  ACTIVATE_USER: { buttonText: 'Розблокувати користувача' },
  DEACTIVATE_USER: { buttonText: 'Блокувати користувача' },
  EDIT_USER: { buttonText: 'Редагувати користувача' },
  UPDATE_NOTE_TEXT: { title: 'Помилка', description: 'Для видалення Запису скористайтеся відповідною кнопкою' },
};

export const MUTATE_QUERY_ERROR_COPIES: Record<ValueOfMutationKeys, string> = {
  [MUTATION_KEYS.UPDATE_FACTION_MUTATION_KEY]: 'Не вдалось оновити угруповання',
  [MUTATION_KEYS.CREATE_FACTION_MUTATION_KEY]: 'Не вдалось створити угруповання',
  [MUTATION_KEYS.REMOVE_FACTION_MUTATION_KEY]: 'Не вдалось видалити угруповання',
  [MUTATION_KEYS.ATTACH_TAG_MUTATION_KEY]: 'Не вдалось прикріпити тег',
  [MUTATION_KEYS.UN_ATTACH_TAG_MUTATION_KEY]: 'Не вдалось відкріпити тег',
  [MUTATION_KEYS.ATTACH_CATEGORY_MANUALLY_MUTATION_KEY]: 'Не вдалось прикріпити категорію',
  [MUTATION_KEYS.UN_ATTACH_CATEGORY_MANUALLY_MUTATION_KEY]: 'Не вдалось відкріпити категорію',
  [MUTATION_KEYS.ATTACH_LOCATION_MUTATION_KEY]: 'Не вдалось прикріпити локацію',
  [MUTATION_KEYS.UN_ATTACH_LOCATION_MUTATION_KEY]: 'Не вдалось відкріпити локацію',
  [MUTATION_KEYS.ATTACH_LOCATION_MANUALLY_MUTATION_KEY]: 'Не вдалось прикріпити локацію',
  [MUTATION_KEYS.UN_ATTACH_LOCATION_MANUALLY_MUTATION_KEY]: 'Не вдалось відкріпити локацію',
  [MUTATION_KEYS.ATTACH_FACTION_MUTATION_KEY]: 'Не вдалось прикріпити угруповання',
  [MUTATION_KEYS.UN_ATTACH_FACTION_MUTATION_KEY]: 'Не вдалось відкріпити угруповання',
  [MUTATION_KEYS.ATTACH_FACTION_MANUALLY_MUTATION_KEY]: 'Не вдалось прикріпити угруповання',
  [MUTATION_KEYS.UN_ATTACH_FACTION_MANUALLY_MUTATION_KEY]: 'Не вдалось відкріпити угруповання',
  [MUTATION_KEYS.CREATE_CALL_SIGN_FROM_TRANSCRIPT_MUTATION_KEY]: 'Не вдалось створити позивний',
  [MUTATION_KEYS.ATTACH_CALL_SIGN_MUTATION_KEY]: 'Не вдалось прикріпити позивний',
  [MUTATION_KEYS.UN_ATTACH_CALL_SIGN_MUTATION_KEY]: 'Не вдалось відкріпити позивний',
  [MUTATION_KEYS.CHANGE_PASSWORD_MUTATION_KEY]: 'Не вдалось оновити пароль',
  [MUTATION_KEYS.RESET_PASSWORD_MUTATION_KEY]: 'Не вдалось оновити пароль',
  [MUTATION_KEYS.VERIFY_CODE_MUTATION_KEY]: 'Не вдалось верифікувати код',
  [MUTATION_KEYS.CONFIRM_GOOGLE_AUTH_MUTATION_KEY]: 'Не вдалось підтвердити google аутентифікацію',
  [MUTATION_KEYS.LOGIN_MUTATION_KEY]: 'Не вдалось аутентифікуватись',
  [MUTATION_KEYS.SIGNUP_MUTATION_KEY]: 'Не вдалось зареєструватись',
  [MUTATION_KEYS.ACTIVATE_USER_MUTATION_KEY]: 'Не вдалось розблокувати користувача',
  [MUTATION_KEYS.DEACTIVATE_USER_MUTATION_KEY]: 'Не вдалось блокувати користувача',
  [MUTATION_KEYS.CREATE_NOTE_MUTATION_KEY]: 'Не вдалось створити нотаток',
  [MUTATION_KEYS.UPDATE_NOTE_MUTATION_KEY]: 'Не вдалось оновити нотаток',
  [MUTATION_KEYS.SUBSCRIPTION_MUTATION_KEY]: 'не вдалося створити підписку',
  [MUTATION_KEYS.SUBSCRIPTION_DELETE_MUTATION_KEY]: 'не вдалося видалити підписку',
  [MUTATION_KEYS.SUBSCRIPTION_UPDATE_MUTATION_KEY]: 'не вдалося оновити підписку',
  [MUTATION_KEYS.UPDATE_USER_MUTATION_KEY]: 'Не вдалось редагувати користувача',
  [MUTATION_KEYS.REMOVE_ACCESS_GROUP_MUTATION_KEY]: 'Не вдалось видалити групу доступів',
  [MUTATION_KEYS.CREATE_CATEGORY_MUTATION_KEY]: 'Не вдалось створити категорію',
  [MUTATION_KEYS.UPDATE_CATEGORY_MUTATION_KEY]: 'Не вдалось оновити категорію',
  [MUTATION_KEYS.UPDATE_ACCESS_GROUP_MUTATION_KEY]: 'Не вдалось оновити групу доступів',
  [MUTATION_KEYS.CREATE_ACCESS_GROUP_MUTATION_KEY]: 'Не вдалось створити групу доступів',
  [MUTATION_KEYS.REMOVE_CATEGORY_MUTATION_KEY]: 'Не вдалось видалити категорію',
  [MUTATION_KEYS.REMOVE_CATEGORY_TAG_MUTATION_KEY]: 'Не вдалось видалити тег',
  [MUTATION_KEYS.REMOVE_NOTE_MUTATION_KEY]: 'Не вдалось видалити нотаток',
  [MUTATION_KEYS.CREATE_CODE_MUTATION_KEY]: 'Не вдалось створити код',
  [MUTATION_KEYS.UPDATE_CODE_MUTATION_KEY]: 'Не вдалося зберегти код',
  [MUTATION_KEYS.REMOVE_CODE_MUTATION_KEY]: 'Не вдалось видалити код',
  [MUTATION_KEYS.REMOVE_CALL_SIGN_MUTATION_KEY]: 'Не вдалось видалити позивний',
  [MUTATION_KEYS.UPDATE_CALL_SIGN_MUTATION_KEY]: 'Не вдалось оновити позивний',
  [MUTATION_KEYS.CREATE_CALL_SIGN_MUTATION_KEY]: 'Не вдалось створити позивний',
};

export const QUERY_ERROR_COPIES: Record<ValueOfQueryKeys, string> = {
  [QUERY_KEYS.NETWORKS_QUERY_KEY]: 'Не вдалось завантажити частоти та мережі',
  [QUERY_KEYS.RADIO_NETWORK_QUERY_KEY]: 'Не вдалось завантажити мережу',
  [QUERY_KEYS.ALL_FREQUENCIES_KEY]: 'Не вдалось завантажити частоти',
  [QUERY_KEYS.ALL_NETWORKS_KEY]: 'Не вдалось завантажити мережі',
  [QUERY_KEYS.RADIO_NETWORKS_FILTER_QUERY_KEY]: 'Не вдалось завантажити фільтри для частот',
  [QUERY_KEYS.TRANSCRIPTS_FILTER_QUERY_KEY]: 'Не вдалось завантажити фільтри для перехоплень',
  [QUERY_KEYS.LOCATIONS_QUERY_KEY]: 'Не вдалось завантажити локації',
  [QUERY_KEYS.LOCATIONS_BY_IDS_QUERY_KEY]: 'Не вдалось завантажити локації',
  [QUERY_KEYS.FACTIONS_QUERY_KEY]: 'Не вдалось завантажити угруповання',
  [QUERY_KEYS.FACTION_BY_ID_QUERY_KEY]: 'Не вдалось завантажити деталі угруповання',
  [QUERY_KEYS.CATEGORIES_QUERY_KEY]: 'Не вдалось завантажити категорії',
  [QUERY_KEYS.CATEGORY_TAGS_QUERY_KEY]: 'Не вдалось завантажити теги',
  [QUERY_KEYS.KML_DATA_KEY]: 'Не вдалось завантажити дані для лінії бойового зіткнення',
  [QUERY_KEYS.CATEGORIES_RECOMMENDED_QUERY_KEY]: 'Не вдалось завантажити категорії',
  [QUERY_KEYS.NOTES_QUERY_KEY]: 'Не вдалось завантажити нотатки',
  [QUERY_KEYS.USER_DATA_QUERY_KEY]: 'Не вдалось завантажити дані користувача',
  [QUERY_KEYS.USER_ROLES_QUERY_KEY]: 'Не вдалось завантажити ролі користувача',
  [QUERY_KEYS.USERS_LIST_QUERY_KEY]: 'Не вдалось завантажити список користувачів',
  [QUERY_KEYS.ACCESS_GROUPS_QUERY_KEY]: 'Не вдалось завантажити групи доступів',
  [QUERY_KEYS.STATISTICS_QUERY_KEY]: 'Не вдалось завантажити статистику',
  [QUERY_KEYS.INTERCEPTIONS_ACTIVITY_QUERY_KEY]: 'Не вдалось завантажити активність перехоплень',
  [QUERY_KEYS.SUBSCRIPTIONS_QUERY_KEY]: 'Не вдалось завантажити підписки',
  [QUERY_KEYS.QR_CODE_QUERY_KEY]: 'Не вдалось завантажити qr код',
  [QUERY_KEYS.DETAILS_QUERY_KEY]: 'Не вдалось завантажити деталі',
  [QUERY_KEYS.CODES_QUERY_KEY]: 'Не вдалось завантажити коди',
  [QUERY_KEYS.CALL_SIGNS_QUERY_KEY]: 'Не вдалось завантажити позивні',
  [QUERY_KEYS.CALL_SIGN_BY_ID_QUERY_KEY]: 'Не вдалось завантажити позивний',
  [QUERY_KEYS.SUPPORT_QUERY_KEY]: 'Не вдалось завантажити перевірку сервера',
};
