export const CREATE_SOURCE_MUTATION_KEY = 'CREATE_SOURCE';
export const UPDATE_SOURCE_AREA_MUTATION_KEY = 'UPDATE_SOURCE_AREA';
export const CREATE_FOX_KEY_MUTATION_KEY = 'CREATE_FOX_KEY';
export const ACTIVATE_FOX_MUTATION_KEY = 'ACTIVATE_FOX';
export const RELOAD_FOX_MUTATION_KEY = 'RELOAD_FOX';
export const SAVE_FOX_SETTINGS_MUTATION_KEY = 'SAVE_FOX_SETTINGS';
export const CREATE_FOX_FREQUENCY_MUTATION_KEY = 'CREATE_FOX_FREQUENCY';
export const UPDATE_RECEIVER_MUTATION_KEY = 'UPDATE_RECEIVER';
export const UPGRADE_FOX_MUTATION_KEY = 'UPGRADE_FOX';
export const ACTIVATE_FOX_FREQUENCY_MUTATION_KEY = 'ACTIVATE_FOX_FREQUENCY';
export const DEACTIVATE_FOX_FREQUENCY_MUTATION_KEY = 'DEACTIVATE_FOX_FREQUENCY';
export const DELETE_FOX_FREQUENCY_MUTATION_KEY = 'DELETE_FOX_FREQUENCY';
export const DELETE_FOX_FREQUENCIES_MUTATION_KEY = 'DELETE_FOX_FREQUENCIES';
export const ACTIVATE_FOX_FREQUENCIES_MUTATION_KEY = 'ACTIVATE_FOX_FREQUENCIES';
export const DEACTIVATE_FOX_FREQUENCIES_MUTATION_KEY = 'DEACTIVATE_FOX_FREQUENCIES';
export const DELETE_FOX_KEY_MUTATION_KEY = 'DELETE_FOX_KEY';
export const UPDATE_FOX_FREQUENCY_MUTATION_KEY = 'UPDATE_FOX_FREQUENCY';
export const DELETE_RECORD_MUTATION_KEY = 'DELETE_RECORD';
export const MARK_VOICE_EXIST_MUTATION_KEY = 'MARK_VOICE_EXIST';
export const MARK_VOICE_SKIP_MUTATION_KEY = 'MARK_VOICE_SKIP';
export const CREATE_NETWORK_MUTATION_KEY = 'CREATE_NETWORK';
export const UPDATE_NETWORK_MUTATION_KEY = 'UPDATE_NETWORK';
export const ADD_TO_NETWORK_MUTATION_KEY = 'ADD_TO_NETWORK';
export const CREATE_UNIT_MUTATION_KEY = 'CREATE_UNIT';
export const UPDATE_UNIT_MUTATION_KEY = 'UPDATE_UNIT';
export const UPDATE_UNIT_AREA_MUTATION_KEY = 'UPDATE_UNIT_AREA';
export const UPDATE_UNIT_USER_MUTATION_KEY = 'UPDATE_UNIT_USER';
export const ACTIVATE_UNIT_USER_MUTATION_KEY = 'ACTIVATE_UNIT_USER';
export const DEACTIVATE_UNIT_USER_MUTATION_KEY = 'DEACTIVATE_UNIT_USER';
export const UPDATE_UNIT_SOURCE_MUTATION_KEY = 'UPDATE_UNIT_SOURCE';
export const UPDATE_TRANSCRIPT_MUTATION_KEY = 'UPDATE_TRANSCRIPT';
