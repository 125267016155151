export const COPIES = {
  NEXT: 'Продовжити',
  EDIT: 'Редагувати',
  REMOVE: 'Видалити',
  CANCEL: 'Скасувати',
  ADD: 'Додати',
  CREATE: 'Створити',
  APPLY: 'Застосувати',
  SAVE: 'Зберегти',
  DATE: 'Дата',
  CLIPBOARD_COPY: 'Скопійовано в буфер обміну',
  COORDINATES_CLIPBOARD_COPY_ERROR: 'Не вдалося скопіювати координати в буфер обміну',
  SEARCH_PLACEHOLDER: 'Пошук',
  NO_DATA: 'Немає даних',
  SOMETHING_WRONG: 'Щось пішло не за планом',
  DATA_IS_LOADING: 'Почекайте, будь ласка, данні завантажуються',
  CHANGES_IS_APPLYING: 'Почекайте, будь ласка, застосовуються зміни',
  LOADING: 'Завантаження...',
  CONNECTION_FAILED: 'Помилка мережі',
  OFFLINE_STATUS: "Перевірте інтернет-з'єднання",
  ONLINE_STATUS: "З'єднання відновлено",
  NO_ACTIVITY: 'Немає активності',
  FILTERS: 'Фільтри',
  ANALOG: 'Аналогові',
  DIGITAL: 'Цифрові',
  ENCRYPTION_TYPE: 'Тип шифрування',

  // auth's constants used in shared layout
  SCAN_QR_CODE: 'Завантажте Google Authenticator',
  TWO_FACTOR_AUTH: 'Двофакторна авторизація',
  CONNECT_TO_GOOGLE_AUTH: 'Підключити двофакторну авторизацію',
  PHONE_VERIFICATION: 'Підтвердження телефону',
  CONTACT_SUPPORT: 'Технічна підтримка\r\nSignal: +38 (093) 148 2896',
  CONTACT_SUPPORT_PHONE: '+38 (093) 148 2896',

  // frequency's constant used in Network entity
  FREQUENCY: 'Частота',

  // navigation menu constants used in shared layout
  SUBSCRIPTION: 'Підписка',
  PROFILE: 'Профіль',
  NOTIFICATIONS: 'Сповіщення',
  BY_FREQUENCY_TYPE: 'За типом сигналу',
};
