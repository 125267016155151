import { StorageService } from '@/shared/types/services';
import { isJsonString } from '@/shared/utils';

class LocalStorageService<T> implements StorageService<T> {
  constructor(private key: string) {}

  getStorageValue(): T | null {
    const value: string | null = localStorage.getItem(this.key);

    if (typeof value === 'string') {
      return this.parseStorageValue(value);
    }

    return null;
  }

  setStorageValue(value: T) {
    localStorage.setItem(this.key, typeof value === 'string' ? value : JSON.stringify(value));
    window.dispatchEvent(new CustomEvent<T | null>(`storage-${this.key}`, { detail: value }));
  }

  removeStorageValue() {
    localStorage.removeItem(this.key);
    window.dispatchEvent(new CustomEvent<T | null>(`storage-${this.key}`, { detail: null }));
  }

  parseStorageValue(value: unknown) {
    if (typeof value !== 'string') return value as T;

    return (isJsonString(value) ? JSON.parse(value) : value) as T;
  }
}

export default LocalStorageService;
