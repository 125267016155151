import { CoordinatesConverterService } from '@/shared/services/CoordinatesConverterService';
import { LatLngCoordinates } from '@/shared/types';

export class CoordinatesParserService {
  private LINE_START = /^\s*/;

  private LINE_END = /\s{0,10}$/;

  private COMA_OR_SPACES = /(,\s*|\s+)/;

  constructor(private converterService: CoordinatesConverterService) {}

  private parseByTemplate(query: string, tmpl: RegExp): LatLngCoordinates | null {
    const match = tmpl.exec(query);
    const resArray = match?.length
      ? (query
          .trim()
          .replace(/(,\s*|\s+)/, ',')
          .split(',') as [string, string])
      : null;
    return resArray ? { lng: Number(resArray[1]), lat: Number(resArray[0]) } : null;
  }

  private tryToParseHyphens(query: string): string | null {
    const WITH_HYPHEN = /(\d{2}-\d{4,8})/;
    const tmplWithHyphen = new RegExp(
      `${this.LINE_START.source}(${WITH_HYPHEN.source}${this.COMA_OR_SPACES.source}${WITH_HYPHEN.source})${this.LINE_END.source}`,
      'g'
    );
    const result = tmplWithHyphen.exec(query);
    return result?.length ? query.replaceAll(/-/g, '') : null;
  }

  tryToParseWGS84(query: string) {
    const WITH_DOT = /\d{2,3}\.\d{1,16}/;
    const tmpl = new RegExp(
      `${this.LINE_START.source}(${WITH_DOT.source}${this.COMA_OR_SPACES.source}${WITH_DOT.source})${this.LINE_END.source}`,
      'g'
    );
    return this.parseByTemplate(query, tmpl);
  }

  tryToParseUCS2000(query: string) {
    const withNoHyphens = this.tryToParseHyphens(query);
    const resultQuery = withNoHyphens || query;
    const DIGITS = /\d{6,10}/;
    const tmpl = new RegExp(
      `${this.LINE_START.source}(${DIGITS.source}${this.COMA_OR_SPACES.source}${DIGITS.source})${this.LINE_END.source}`,
      'g'
    );
    return this.parseByTemplate(resultQuery, tmpl);
  }

  tryToParseMGRS(query: string) {
    const preparedString = query.replace(/\s+/g, '').toUpperCase();
    const mgrsRegEx = /\d{1,2}[^ABIOYZ][A-Z]{2}(\d{2})+$/;
    const isMgrs = mgrsRegEx.test(preparedString);
    return isMgrs ? preparedString : null;
  }

  parseCoordsFromQuery(query: string) {
    const wgs84 = this.tryToParseWGS84(query);
    if (wgs84) return wgs84;
    const ucs2000 = this.tryToParseUCS2000(query);
    if (ucs2000) return this.converterService.convertUCS2000toWGS84(ucs2000.lng, ucs2000.lat);
    const mgrs = this.tryToParseMGRS(query);
    if (mgrs) return this.converterService.convertMGRStoWGS84(mgrs);
  }

  roundsToPrecision(coords?: LatLngCoordinates | null, precision?: number) {
    if (!coords) return null;

    return {
      lat: Number(coords.lat.toFixed(precision)),
      lng: Number(coords.lng.toFixed(precision)),
    };
  }
}

export default new CoordinatesParserService(new CoordinatesConverterService());
