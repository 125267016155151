import { AUTH_DATA_LOCAL_STORAGE_KEY, LOGIN_PATH, paths } from '@/shared/constants';
import { LocalStorageService } from '@/shared/services';

const localStorageService = new LocalStorageService(AUTH_DATA_LOCAL_STORAGE_KEY);

const logoutInactiveUser = () => {
  localStorageService.removeStorageValue();
  window.location.replace(`${LOGIN_PATH}/${paths.USER_INACTIVE_PATH}`);
};

export default logoutInactiveUser;
