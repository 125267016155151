export const UNIT_FREE_SOURCES_ROUTE = 'free-sources';
export const UNIT_FREE_USERS_ROUTE = 'free-users';

export enum UnitTab {
  Users = 'users',
  Sources = 'sources',
  Settings = 'settings',
}

export enum UnitQueryParam {
  Search = 'u_s',
}

export enum UnitUserQueryParam {
  Search = 'u_u_s',
}
