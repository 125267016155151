import { forward as wgs84ToMgrs, inverse as mgrsToWgs84 } from 'mgrs';
import proj4 from 'proj4';
import { LatLngCoordinates, PointCoordinates } from '@/shared/types';
import { CoordinatesSystem, COORDINATES_SYSTEM_MAP } from './constants';

export class CoordinatesConverterService {
  constructor() {
    proj4.defs(CoordinatesSystem.WGS84, '+proj=longlat +datum=WGS84 +no_defs +type=crs');
    proj4.defs(
      CoordinatesSystem.UCS2000_4_ZONE,
      '+proj=tmerc +lat_0=0 +lon_0=21 +k=1 +x_0=4500000 +y_0=0 +ellps=krass +towgs84=24,-121,-76,0,0,0,0 +units=m +no_defs +type=crs'
    );
    proj4.defs(
      CoordinatesSystem.UCS2000_5_ZONE,
      '+proj=tmerc +lat_0=0 +lon_0=27 +k=1 +x_0=5500000 +y_0=0 +ellps=krass +towgs84=24,-121,-76,0,0,0,0 +units=m +no_defs +type=crs'
    );
    proj4.defs(
      CoordinatesSystem.UCS2000_6_ZONE,
      '+proj=tmerc +lat_0=0 +lon_0=33 +k=1 +x_0=6500000 +y_0=0 +ellps=krass +towgs84=24,-121,-76,0,0,0,0 +units=m +no_defs +type=crs'
    );
    proj4.defs(
      CoordinatesSystem.UCS2000_7_ZONE,
      '+proj=tmerc +lat_0=0 +lon_0=39 +k=1 +x_0=7500000 +y_0=0 +ellps=krass +towgs84=24,-121,-76,0,0,0,0 +units=m +no_defs +type=crs'
    );
  }

  private getZoneFromWGS84(lat: number, lng: number) {
    const zones = [
      { zone: 4, minLng: 22.15, maxLng: 24.0, minLat: 47.95, maxLat: 51.66 },
      { zone: 5, minLng: 24.0, maxLng: 30.0, minLat: 45.1, maxLat: 51.96 },
      { zone: 6, minLng: 30.0, maxLng: 36.0, minLat: 43.18, maxLat: 52.38 },
      { zone: 7, minLng: 36.0, maxLng: 40.18, minLat: 43.43, maxLat: 50.44 },
    ];

    return zones.find(
      ({ minLat, maxLat, minLng, maxLng }) => lat >= minLat && lat <= maxLat && lng >= minLng && lng <= maxLng
    )?.zone;
  }

  private getZoneFromUCS2000(x: string): number {
    const firstChar = x.charAt(0);
    return parseInt(firstChar, 10);
  }

  private getCoordinatesSystem(zone: number): CoordinatesSystem | undefined {
    return COORDINATES_SYSTEM_MAP[zone];
  }

  convert(data: { fromProj: CoordinatesSystem; toProj: CoordinatesSystem; coordinates: number[] }): number[] {
    return proj4(data.fromProj, data.toProj, data.coordinates);
  }

  convertWGS84toUCS2000(lat: number, lng: number): PointCoordinates | null {
    const zone = this.getZoneFromWGS84(lat, lng);
    if (!lng || !lat || !zone) return null;

    const fromProj = CoordinatesSystem.WGS84;
    const toProj = this.getCoordinatesSystem(zone);

    if (!toProj) return null;
    const [resLng, resLat] = this.convert({
      fromProj,
      toProj,
      coordinates: [Number(lng), Number(lat)],
    }).map((val) => Number(val.toFixed(0)));

    return resLng && resLat ? [resLat, resLng] : null;
  }

  convertUCS2000toWGS84(x: number, y: number): LatLngCoordinates | null {
    if (!x || !y) return null;
    const lng = String(x);
    const lat = String(y);
    const zone = this.getZoneFromUCS2000(lng);
    const fromProj = this.getCoordinatesSystem(zone);
    const toProj = CoordinatesSystem.WGS84;

    if (!fromProj) return null;
    const [resLng, resLat] = this.convert({
      fromProj,
      toProj,
      coordinates: [Number(lng), Number(lat)],
    }).map((val) => Number(val.toFixed(7)));

    return resLng && resLat
      ? {
          lng: resLng,
          lat: resLat,
        }
      : null;
  }

  convertWGS84toMGRS({ lat, lng }: LatLngCoordinates): string | null {
    /*
     * mgrs library does not support conversions of points
     * in polar regions below 80°S and above 84°N.
     * Returns null for this case
     */
    try {
      const mgrsCoordinates = wgs84ToMgrs([lng, lat]);
      return mgrsCoordinates || null;
    } catch (e) {
      return null;
    }
  }

  convertMGRStoWGS84(mgrsCoordinates: string): LatLngCoordinates | null {
    const [resLng, resLat] = mgrsToWgs84(mgrsCoordinates);
    return resLng && resLat
      ? {
          lng: resLng,
          lat: resLat,
        }
      : null;
  }
}

export const coordinatesConverterService = new CoordinatesConverterService();
