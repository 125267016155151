import { z } from 'zod';

const paginatedWithFoffsetSchema = <T>(responseData: z.ZodType<T>) =>
  z.object({
    currentPage: z.number(),
    models: z.array(responseData),
    foffset: z.string().nullish(),
  });

export default paginatedWithFoffsetSchema;
