export const UPDATE_FACTION_MUTATION_KEY = 'UPDATE_FACTION';
export const CREATE_FACTION_MUTATION_KEY = 'CREATE_FACTION';
export const REMOVE_FACTION_MUTATION_KEY = 'REMOVE_FACTION';
export const ATTACH_TAG_MUTATION_KEY = 'ATTACH_TAG';
export const UN_ATTACH_TAG_MUTATION_KEY = 'UN_ATTACH_TAG';
export const ATTACH_CATEGORY_MANUALLY_MUTATION_KEY = 'ATTACH_CATEGORY_MANUALLY';
export const UN_ATTACH_CATEGORY_MANUALLY_MUTATION_KEY = 'UN_ATTACH_CATEGORY_MANUALLY';
export const ATTACH_LOCATION_MUTATION_KEY = 'ATTACH_LOCATION';
export const UN_ATTACH_LOCATION_MUTATION_KEY = 'UN_ATTACH_LOCATION';
export const ATTACH_LOCATION_MANUALLY_MUTATION_KEY = 'ATTACH_LOCATION_MANUALLY';
export const UN_ATTACH_LOCATION_MANUALLY_MUTATION_KEY = 'UN_ATTACH_LOCATION_MANUALLY';
export const ATTACH_FACTION_MUTATION_KEY = 'ATTACH_FACTION';
export const UN_ATTACH_FACTION_MUTATION_KEY = 'UN_ATTACH_FACTION';
export const ATTACH_FACTION_MANUALLY_MUTATION_KEY = 'ATTACH_FACTION_MANUALLY';
export const UN_ATTACH_FACTION_MANUALLY_MUTATION_KEY = 'UN_ATTACH_FACTION_MANUALLY';
export const CREATE_CALL_SIGN_FROM_TRANSCRIPT_MUTATION_KEY = 'CREATE_CALL_SIGN_FROM_TRANSCRIPT';
export const ATTACH_CALL_SIGN_MUTATION_KEY = 'ATTACH_CALL_SIGN';
export const UN_ATTACH_CALL_SIGN_MUTATION_KEY = 'UN_ATTACH_CALL_SIGN';
export const CHANGE_PASSWORD_MUTATION_KEY = 'CHANGE_PASSWORD';
export const RESET_PASSWORD_MUTATION_KEY = 'RESET_PASSWORD';
export const VERIFY_CODE_MUTATION_KEY = 'VERIFY_CODE';
export const CONFIRM_GOOGLE_AUTH_MUTATION_KEY = 'CONFIRM_GOOGLE_AUTH';
export const LOGIN_MUTATION_KEY = 'LOGIN';
export const SIGNUP_MUTATION_KEY = 'SIGNUP';
export const ACTIVATE_USER_MUTATION_KEY = 'ACTIVATE_USER';
export const DEACTIVATE_USER_MUTATION_KEY = 'DEACTIVATE_USER';
export const CREATE_NOTE_MUTATION_KEY = 'CREATE_NOTE';
export const UPDATE_NOTE_MUTATION_KEY = 'UPDATE_NOTE';
export const REMOVE_NOTE_MUTATION_KEY = 'REMOVE_NOTE';
export const SUBSCRIPTION_MUTATION_KEY = 'SUBSCRIPTION_MUTATION';
export const SUBSCRIPTION_DELETE_MUTATION_KEY = 'SUBSCRIPTION_DELETE';
export const SUBSCRIPTION_UPDATE_MUTATION_KEY = 'SUBSCRIPTION_UPDATE';
export const UPDATE_USER_MUTATION_KEY = 'UPDATE_USER';
export const CREATE_ACCESS_GROUP_MUTATION_KEY = 'CREATE_ACCESS_GROUP';
export const UPDATE_ACCESS_GROUP_MUTATION_KEY = 'UPDATE_ACCESS_GROUP';
export const REMOVE_ACCESS_GROUP_MUTATION_KEY = 'REMOVE_ACCESS_GROUP';
export const CREATE_CATEGORY_MUTATION_KEY = 'CREATE_CATEGORY';
export const UPDATE_CATEGORY_MUTATION_KEY = 'UPDATE_CATEGORY';
export const REMOVE_CATEGORY_MUTATION_KEY = 'REMOVE_CATEGORY';
export const CREATE_CODE_MUTATION_KEY = 'CREATE_CODE';
export const UPDATE_CODE_MUTATION_KEY = 'UPDATE_CODE';
export const REMOVE_CODE_MUTATION_KEY = 'REMOVE_CODE';
export const REMOVE_CATEGORY_TAG_MUTATION_KEY = 'REMOVE_CATEGORY_TAG';
export const REMOVE_CALL_SIGN_MUTATION_KEY = 'REMOVE_CALL_SIGN';
export const UPDATE_CALL_SIGN_MUTATION_KEY = 'UPDATE_CALL_SIGN';
export const CREATE_CALL_SIGN_MUTATION_KEY = 'CREATE_CALL_SIGN';
