import dayjs from 'dayjs';
import { FormItemValidation } from '@/shared/types';
import {
  OVERVIEW_PATH,
  ALL_INTERCEPTIONS_PATH,
  RADIO_NETWORKS_PATH_BASE,
  SOURCES_PATH_BASE,
  FACTIONS_PATH_BASE,
  CATEGORIES_PATH_BASE,
  ACCESS_GROUPS_PATH_BASE,
  UNITS_PATH_BASE,
} from './paths';

export * from './copies';
export * from './queryKeys';
export * from './mutationKeys';
export * from './queryResultCopies';
export * from './paths';
export * as paths from './paths';
export * from './routeEnums';
export * from './neutralColorPalette';
export * from './dateTimeFormats';

export const PHONE_MASK_PATTERN = '+000 00 000 00 00';

export const AUTH_DATA_LOCAL_STORAGE_KEY = 'X-auth';

export const PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY = 'saved-path';

export const DEBOUNCE_DEFAULT = 600;

export const THROTTLE_DEFAULT = 50;

export const DEFAULT_PAGE_SIZE = '100';

export const MAX_REFETCH_INTERVAL = 60_000;

export const MID_REFETCH_INTERVAL = 30_000;

export const MIN_REFETCH_INTERVAL = 15_000;

export const DISABLED_TIME_ON_SCROLL_TOP = 1000;

export const DISABLED_REFETCH_SCROLL_TOP = 200;

export const LABEL_SEPARATOR = ' • ';

export const SEARCH_MIN_LENGTH = 2;

export const enum Page {
  Overview = 'Огляд',
  AllInterceptions = 'Всі перехоплення',
  RadioNetworks = 'Мережі / частоти',
  Sources = 'Джерела',
  Factions = 'Угруповання',
  Categories = 'Категорії',
  AccessGroups = 'Доступи',
  Units = 'Підрозділи',
}

export enum RoleName {
  USER = 'USER',
  OPERATOR = 'OPERATOR',
  ANALYST = 'ANALYST',
  TECHNOLOGIST = 'TECHNOLOGIST',
  SUPERVISOR = 'SUPERVISOR',
  ADMIN = 'ADMIN',
}

export const ROLES_DESCRIPTION: Record<RoleName, { label: string; description: string }> = {
  USER: {
    label: 'Створений користувач',
    description: '',
  },
  OPERATOR: {
    label: 'Аналітик розвідки',
    description:
      'Цей учасник може працювати з частотами та мережами - збирати, обробляти та систематизувати перехоплення',
  },
  ANALYST: {
    label: 'Аналітик мереж',
    description: '',
  },
  TECHNOLOGIST: {
    label: 'Технік',
    description: 'Цей учасник може працювати з частотами, мережами та джерелами',
  },
  SUPERVISOR: {
    label: 'Адмін підрозділу',
    description:
      'Цей учасник додатково може керувати користувачами в своїй та дочірніх групах та запрошувати нових користувачів',
  },
  ADMIN: {
    label: 'Технічна підтримка',
    description: '',
  },
} as const;

export enum RadioNetworkType {
  FREQUENCY = 'frequency',
  NETWORK = 'network',
}

export const enum EntityType {
  TAG = 'tag',
  LOCATION = 'location',
  FACTION = 'faction',
  CALL_SIGN = 'callSign',
  CODE = 'code',
  SEARCH = 'search',
}

export enum SortType {
  Ascending = 'asc',
  Descending = 'desc',
  None = '',
}

export enum SortHint {
  Ascending = 'Натисни для сортування за зростанням',
  Descending = 'Натисни для сортування за зменшенням',
  Cancel = 'Натисни для скасування сортування',
}

export enum FilterType {
  Network = 'network',
  AllTranscripts = 'transcript',
  NetworkTranscripts = 'networkTranscript',
}

export enum MobileView {
  Menu = 'menu',
  PrimaryScreen = '',
  SecondaryScreen = 'secondary',
}

export enum MobileDetailsManagerState {
  View = 'view',
  Edit = 'edit',
  Create = 'create',
}

export const EMPTY_FORM_ITEM_VALIDATION: FormItemValidation = {
  validateStatus: undefined,
  help: null,
};

const USER_PAGES = [OVERVIEW_PATH, ALL_INTERCEPTIONS_PATH, RADIO_NETWORKS_PATH_BASE];
const OPERATOR_PAGES = [...USER_PAGES, FACTIONS_PATH_BASE];

export const ALLOWED_PAGES: Record<RoleName, string[]> = {
  USER: USER_PAGES,
  OPERATOR: OPERATOR_PAGES,
  ANALYST: OPERATOR_PAGES,
  TECHNOLOGIST: [...OPERATOR_PAGES, SOURCES_PATH_BASE],
  SUPERVISOR: [...OPERATOR_PAGES, ACCESS_GROUPS_PATH_BASE],
  ADMIN: [...OPERATOR_PAGES, SOURCES_PATH_BASE, ACCESS_GROUPS_PATH_BASE, CATEGORIES_PATH_BASE, UNITS_PATH_BASE],
};

export const MOBILE_MAX_WIDTH = 720;

export const TABLET_MAX_WIDTH = 1259;

export const SPACE_KEY = ' ';

export const PLUS_KEY = '+';

export const MINUS_KEY = '-';

export const SPECTROGRAM_DRAWER_CLASSNAME = 'spectrogram-drawer';

export const MAP_SETTINGS_STORAGE_KEY = 'mapSettings';

export const LIMIT_MIN_DATE = dayjs('2023-01-01');
