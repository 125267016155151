import { AUTH_DATA_LOCAL_STORAGE_KEY, LOGIN_PATH, PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY } from '@/shared/constants';
import { LocalStorageService } from '@/shared/services';

type Options = {
  shouldSaveLastRoute?: boolean;
};

const localStoragePathBeforeLogoutService = new LocalStorageService(PATH_BEFORE_LOGOUT_LOCAL_STORAGE_KEY);
const localStorageAuthDataService = new LocalStorageService(AUTH_DATA_LOCAL_STORAGE_KEY);

const logout = ({ shouldSaveLastRoute = false }: Options = {}) => {
  if (shouldSaveLastRoute) {
    localStoragePathBeforeLogoutService.setStorageValue(`${window.location.pathname}${window.location.search}`);
  }
  localStorageAuthDataService.removeStorageValue();
  window.location.replace(LOGIN_PATH);
  window.location.reload();
};

export default logout;
