import { z } from 'zod';
import { baseCategorySchema } from './categorySchemas';
import { codeSchema } from './codeSchemas';
import { baseFactionSchema } from './factionSchemas';
import { locationSchema } from './locationSchemas';
import { sourceTypeSchema } from './sourceSchemas';

export const transcriptSourceSchema = z.object({
  id: z.number(),
  name: z.string().nullish(),
  type: sourceTypeSchema.nullish(),
  creatorName: z.string().nullish(),
  transcriptId: z.string(),
});

export const transcriptFrequencySchema = z.object({
  id: z.string(),
  value: z.string(),
});

export const transcriptNetworkSchema = z.object({
  networkId: z.string().nullish(),
  networkName: z.string().nullish(),
});

export const transcriptTagSchemaV1 = z.object({
  id: z.string(),
  text: z.string(),
  category_id: z.number().nullish(),
  sub_category_id: z.number(),
});

export const transcriptTagSchema = z.object({
  id: z.string(),
  text: z.string(),
  categoryId: z.number().nullish(),
  subCategoryId: z.number(),
});

export const transcriptFactionSchemaV1 = z
  .object({
    alias: z.string(),
    aliases: z.array(z.string()),
    country: z.string().nullish(),
  })
  .merge(baseFactionSchema);

export const transcriptFactionSchema = z.object({
  id: z.string(),
  name: z.string(),
  shortName: z.string(),
  fullName: z.string(),
  fullShortName: z.string(),
  country: z.string().nullish(),
  alias: z.string(),
  aliases: z.array(z.string()),
});

export const transcriptLocationSchema = z
  .object({
    alias: z.string(),
  })
  .merge(locationSchema);

export const transcriptCategorySchema = z
  .object({
    parent: baseCategorySchema,
  })
  .merge(baseCategorySchema);

export const transcriptCodeSchema = codeSchema.pick({ id: true, name: true, value: true });

export const transcriptCallSignSchema = z.object({
  id: z.string(),
  callsignId: z.string(),
  name: z.string(),
  alias: z.string(),
});
