export class InvisibleTextService {
  private invisibleZero = '\u200B';

  private invisibleOne = '\u200C';

  injectInvisibleText({
    originalText,
    injectableInvisibleText,
  }: {
    originalText: string;
    injectableInvisibleText: string;
  }) {
    const binary = this.toBinary(injectableInvisibleText);
    const zeroWidth = this.binaryToZeroWidth(binary);
    const midIndex = Math.floor(originalText.length / 2);
    const modifiedText = this.insertAt(originalText, midIndex, zeroWidth);

    return modifiedText;
  }

  replaceInvisibleSymbols(text: string) {
    return text.replaceAll(this.invisibleZero, '').replaceAll(this.invisibleOne, '');
  }

  private toBinary(text: string) {
    return text
      .split('')
      .map((char) => char.charCodeAt(0).toString(2).padStart(8, '0'))
      .join('');
  }

  private binaryToZeroWidth(binary: string) {
    return binary.replace(/0/g, this.invisibleZero).replace(/1/g, this.invisibleOne);
  }

  private insertAt(text: string, index: number, insertion: string) {
    return text.slice(0, index) + insertion + text.slice(index);
  }
}

export const invisibleTextService = new InvisibleTextService();
